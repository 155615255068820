// App imports
import './styles.scss';

// Context imports
import { useSurvey } from '../../context/survey';

// Third-party imports
import { useNavigate } from 'react-router-dom';

const abilities = [
	"DISABLED",
	"ABLE-BODIED",
	"OTHER",
]

export const Ability = () => {
	const navigate = useNavigate();
	const { ability, setAbility } = useSurvey();
	
	const onClick = (currentIndex: any) => {
		setAbility(abilities[currentIndex]);
		navigate('/world')
	};

	return (
		<div className="ability">
			<div className="ability-title">Which one of these suits your physical ability?</div>
			<div className="ability-items">
				{abilities.map((item: any, index: number) => {
					return (
						<div key={index} onClick={() => onClick(index)}>{item}</div>
					)
				})}
			</div>
		</div>
	)
}

Ability.displayName="Ability";
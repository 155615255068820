// React imports
import { useCallback } from 'react';

// App imports
import { Path } from './path';

// Context imports
import { useMapbox } from '../../../context/maps/mapbox';

// Third-party imports
import { Marker } from 'react-map-gl';

export const UserPin = () => {
	const { viewport, setViewport } = useMapbox();

	const onMarkerDragEnd = useCallback((event: any) => {
	    setViewport({...viewport,
	        longitude: event.lngLat.lng,
	        latitude: event.lngLat.lat
	    });
	}, []);

	return (
			<Marker
				longitude={viewport.longitude}
				latitude={viewport.latitude}
				anchor="bottom"
				draggable
				onDragEnd={onMarkerDragEnd}
			>
		      <svg 
		      	viewBox="0 0 45.1 63.3"
		      	width="35px" 
		      	fill="rgba(233, 12, 131, 1)"
		      >
		        <Path/>
		      </svg>
		    </Marker>
	)
}

UserPin.displayName="UserPin";
// React imports	
import { useState, useEffect, useRef, useCallback } from 'react';

// App imports
import { Search } from './search';
import { Title } from './title';
import { CTA } from './cta';
import { UserPin } from './userPin';
import './styles.scss';

// Context imports
import { useMapbox } from '../../context/maps/mapbox';

// Third-party imports
import { Map } from 'react-map-gl';

export const World = () => {
	const cursorRef = useRef<any>(null);
	const [ activeCursor, setActiveCursor ] = useState(true);
	const { setViewport, viewport, Locations, worldRef, isInitialState, setIsInitialState } = useMapbox();
	const [ isMapLoaded, setIsMapLoaded ] = useState(false);

	useEffect(() => {
        if (cursorRef.current) {
            const { innerWidth, innerHeight } = window;
            cursorRef.current.style.left = `${innerWidth / 2 - 30}px`;
            cursorRef.current.style.top = `${innerHeight / 2 - 30}px`;
        }
    }, []);

    // Handle cursor movement on desktop
    useEffect(() => {
        const moveCursor = (e: MouseEvent) => {
            if (cursorRef.current) {
                cursorRef.current.style.left = `${e.pageX - 30}px`;
                cursorRef.current.style.top = `${e.pageY - 30}px`;
            }
        };

        window.addEventListener('mousemove', moveCursor);

        return () => {
            window.removeEventListener('mousemove', moveCursor);
        };
    }, []);

    const onClick = useCallback((event: any) => {
    	const lng = event.lngLat.lng;
    	const lat = event.lngLat.lat;
    	setViewport({ ...viewport, longitude: lng, latitude: lat });
        setIsInitialState(false);
    }, []);

    const onMouseEnter = () => setActiveCursor(false);
    const onMouseLeave = () => setActiveCursor(true);

	return (
		 <div className={`world-wrapper ${!isInitialState ? 'cursor-visible' : 'cursor-hidden'}`}>
			<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				<Search/>
			</div>
			<Title isInitialState={isInitialState}/>
			<Map
				ref={worldRef}
				initialViewState={Locations.other}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} 
				mapStyle={"mapbox://styles/hvoking/cm1h7n1kp01ed01pd24g689ob"}
				onClick={onClick}
				doubleClickZoom={false}
				onLoad={() => setIsMapLoaded(true)}
			>	
                {isMapLoaded && !isInitialState && <UserPin/>}
			</Map>
			{activeCursor && isInitialState && <div 
				ref={cursorRef} 
				className="go-circle custom-cursor" 
				id="go-cursor"
			>
				GO
			</div>}
			{!isInitialState && 
                <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
					<CTA/>
                </div>
            }
		</div>
	)
}
World.displayName="World";
export const rotterdam = {
  bearing: 0,
  latitude: 51.9233,
  longitude: 4.4866,
  pitch: 0,
  zoom: 10,
};

export const other = {
  latitude: 51.9233,
  longitude: 4.4866,
  zoom: 2,
};
// App imports
import './styles.scss';

// Third-party imports
import { useNavigate } from 'react-router-dom';

export const CTA = () => {
	const navigate = useNavigate();
	const onClick = () => navigate('/maps');

	return (
		<div className="world-cta" onClick={onClick}>
			START MAPPING
		</div>
	)
}

CTA.displayName="CTA";
// App imports
import './styles.scss';

// Third-party imports
import { useNavigate } from 'react-router-dom';

export const CTA = () => {
	const navigate = useNavigate();
	const onClick = () => navigate('/vision');
	
	return (
		<div 
			className="page2-cta" 
			onClick={onClick}
		>
			Let's go!
		</div>
	)
}

CTA.displayName="CTA";
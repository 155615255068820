// React imports
import { useState } from 'react';

// App imports
import { Unoun } from './unoun';
import './styles.scss';

// Third-party imports
import { useNavigate } from 'react-router-dom';

export const Ending = () => {
	const navigate = useNavigate();
	const [ activeMobile, setActiveMobile ] = useState(false);

	window.addEventListener('resize', () => {
	  let innerWidth = window.innerWidth;
	  innerWidth > 1000 ? 
	  setActiveMobile(false) : 
	  setActiveMobile(true)
	});

	const backgroundUrl = process.env.PUBLIC_URL + '/static/background/background.png';
	const backgroundMobileUrl = process.env.PUBLIC_URL + '/static/background/background_mobile.png';
	
	return (
		<div
			className="ending-wrapper"
			style={{
				backgroundImage: 
					activeMobile || window.innerWidth < 768 ?
					`url(${backgroundMobileUrl})` :
					`url(${backgroundUrl})`
			}}
		>
			<div className="ending">
				<div className="ending-top">
					<div className="thanks-message">Thank you once again!</div>
					<div className="ending-title">
						<img className="logo-ending" src={process.env.PUBLIC_URL + "/static/logos/logo_white.svg"} alt="logo"/>
					</div>
					<div className="ending-subtitle">
						An atlas trained by citizens to design more feminist cities
					</div>
				</div>
				<div className="ending-cta" onClick={() => navigate("/")}>Back to start</div>
				<Unoun/>
			</div>

		</div>
	)
}

Ending.displayName="Ending";
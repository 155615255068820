// App imports
import './styles.scss';

export const Subtitle = () => {
	return (
		<div className="intro-subtitle">
			We need your help to design better cities for everyone. We need to <br className="desktop-only"/>
			understand what makes a city inclusive, gender-equitable so we can <br className="desktop-only"/>
			target better interventions in our cities.
		</div>
	)
}

Subtitle.displayName="Subtitle";
// App imports
import { Header } from './header';
import { Questions } from './questions';
import { Topics } from './topics';
import { MapContainer } from './map';
import './styles.scss';

export const Maps = () => {
	return (
		<div className="maps-wrapper">
			<Header/>
			<Topics/>
			<Questions/>
			<MapContainer/>
		</div>
	)
}

Maps.displayName="Maps";
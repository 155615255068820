// App imports
import './styles.scss';

// Context imports
import { useTopics } from 'context/topics';
import { useMarkers } from 'context/maps/markers';

export const Topics = () => {
	const { questions, setQuestions, topics, topicIndex, setTopicIndex } = useTopics();
	const { markers, setMarkers, setCurrentMarker } = useMarkers();

	const onClick = (index: any) => {
		setQuestions((prevQuestions: any) => {
			const updatedQuestions = [...prevQuestions];
			updatedQuestions[topicIndex].pins = markers; 
			return updatedQuestions;
		});
		setMarkers(questions[index].pins);
		setCurrentMarker(null);
		setTopicIndex(index)
	}

	return (
		<div className="topics-wrapper">
			{Object.keys(topics).map((item: any, index: number) => (
				<div 
					key={item}
					className="topics-image" 
					onClick={() => onClick(index)}
					style={{backgroundColor: topicIndex === index ? "rgba(233, 12, 131, 1)" : ""}}
				>
					<img 
						src={process.env.PUBLIC_URL + `/static/topics/${index + 1}.svg`} 
						alt="icon"
						style={{filter: topicIndex === index ? "brightness(0) invert(1)" : ""}}
					/>
				</div>
			))}
		</div>
	)
}

Topics.displayName="Topics";
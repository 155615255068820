// App imports
import { Dropdown } from './dropdown';
import './styles.scss';

export const Race = () => {
	return (
		<div className="race">
			<div></div>
			<div className="race-wrapper">
				<div className="race-title">
					We would like to know more about you first!
				</div>
				<div className="race-subtitle">
					What is your race?
				</div>
				<Dropdown/>
			</div>
			<div></div>
		</div>
	)
}

Race.displayName="Race";
// App imports
import './styles.scss';

// Context imports
import { useSurvey } from '../../context/survey';

// Third-party imports
import { useNavigate } from 'react-router-dom';

const agesArray = [
	"0 - 20",
	"21- 40",
	"41 - 60",
	"61+",
]

export const Ages = () => {
	const navigate = useNavigate();

	const { setAge } = useSurvey();
	
	const onClick = (currentIndex: any) => {
		setAge(agesArray[currentIndex])
		navigate('/ability')
	};

	return (
		<div className="ages">
			<div className="ages-title">How old are you?</div>
			<div className="ages-items">
				{agesArray.map((item: any, index: any) => {
					return (
						<div key={index} onClick={() => onClick(index)}>
							{item}
						</div>
					)
				})}
			</div>
		</div>
	)
}

Ages.displayName="Ages";
import { AddPin } from './add';
import { Trash } from './trash'

export const Buttons = () => {
  return (
    <>
      <AddPin/>
      <Trash/>
    </>
  )
}

Buttons.displayName="Buttons";
// App imports
import { Slider } from './slider';
import { AddPin } from './addPin';
import { Footer } from './footer';
import './styles.scss';

// Context imports
import { useMarkers } from 'context/maps/markers';
import { useTopics } from 'context/topics';

export const Questions = () => {
	const { markers, currentMarker, setMarkers, setCurrentMarker } = useMarkers();
	const { setTopicIndex, topicIndex, topics, questions, setQuestions } = useTopics();

	return (
		<div className="questions-wrapper">
			<div style={{display: "grid"}}>
				<div className="questions">
					<div>
						<div className="questions-template">
							Add a pin and rate it with the slider
						</div>
						<div className="questions-title">
							{questions[topicIndex].question}
						</div>
					</div>
					{markers.length > 0 && currentMarker && <Slider/>}
					<AddPin/>
				</div>
			</div>
			<Footer 
				topics={topics} 
				topicIndex={topicIndex} 
				setTopicIndex={setTopicIndex}
				setMarkers={setMarkers}
				questions={questions}
				setCurrentMarker={setCurrentMarker}
				markers={markers}
				setQuestions={setQuestions}
			/>
		</div>
	)
}

Questions.displayName="Questions";
// App imports
import { Arrow } from '../arrow';

// Context imports
import { useMarkers } from '../../../../../context/maps/markers';

export const Prev = ({ questions, setQuestions, topics, topicIndex, setTopicIndex }: any) => {
	const { markers, setMarkers, setCurrentMarker } = useMarkers();

	return (
		<div>
			<div style={{transform: "rotate(180deg)"}}>
				
					<Arrow 
						setTopicIndex={setTopicIndex}
						nextTopic={topicIndex - 1}
						markers={markers} 
						topicIndex={topicIndex} 
						setMarkers={setMarkers} 
						questions={questions} 
						setCurrentMarker={setCurrentMarker} 
						setQuestions={setQuestions}
					/>
			</div>
			<div className="arrow-text">
				prev
			</div>
		</div>
	)
}

Prev.displayName="Prev";
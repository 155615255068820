// App imports
import { Prev } from './prev';
import { Next } from './next';
import { Done } from './done';
import './styles.scss';

export const Footer = ({ questions, setQuestions, topics, topicIndex, setTopicIndex }: any) => {
	const isLastTopic = topicIndex === Object.keys(topics).length - 1;

	return (
		<div className="arrow-wrapper">
			{
				!isLastTopic ? 
				<div className="arrows-wrapper" style={{justifyContent: topicIndex - 1 > -1 ? 'space-between' : 'center'}}>
					{topicIndex - 1 > -1 && 
						<Prev
							questions={questions} 
							setQuestions={setQuestions}
							topics={topics}
							topicIndex={topicIndex}
							setTopicIndex={setTopicIndex}
						/>
					}
					<Next
						questions={questions} 
						setQuestions={setQuestions}
						topics={topics}
						topicIndex={topicIndex}
						setTopicIndex={setTopicIndex}
					/>
				</div>
				: 
				<Done setTopicIndex={setTopicIndex}/>
			}
		</div>
	)
}

Footer.displayName="Footer";
// App imports
import './styles.scss';

// Third-party imports
import { useNavigate } from 'react-router-dom';

export const Button = () => {
	const navigate = useNavigate();
	
	return (
		<div className="go-button" onClick={() => navigate('/race')}>
			GO
		</div>
	)
}

Button.displayName="Button";
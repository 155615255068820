// App imports
import './styles.scss';

// Context imports
import { useTopics } from 'context/topics';

export const Header = () => {
	const { topics, topicIndex } = useTopics();
	
	return (
		<div className="maps-header">
			<img src={process.env.PUBLIC_URL + "/static/logos/logo.svg"} alt="logo" height="20px"/>
			<div className="maps-topic">
				{topics[topicIndex]}
			</div>
		</div>
	)
}

Header.displayName="Header";
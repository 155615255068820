// App imports
import './styles.scss';

export const Icons = () => {
	return (
		<div className="vision-list">
			<div style={{display: "grid", gridTemplateRows: "min-content auto", gridGap: "10px"}}>
				<div className="vision-image-wrapper">
					<img src="static/icons/search.svg" alt="pin" height="50px"/>
				</div>
				<div>Seach Area</div>
			</div>
			<div style={{display: "grid", alignContent: "center"}}>
				<svg 
					viewBox="0 0 20 20" 
					width="30px" 
					style={{transform: "translateY(-20px)"}}
				>
					<polyline
						points="6,7 14,13 6,19"
						stroke="rgba(233, 12, 131, 1)"
						strokeWidth="3"
						fill="none"
					/>
				</svg>
			</div>
			<div style={{display: "grid", gridTemplateRows: "min-content auto", gridGap: "10px"}}>
				<div className="vision-image-wrapper">
					<img src="static/icons/pin.svg" alt="pin" height="50px"/>
				</div>
				<div>Add a pin</div>
			</div>
			<div style={{display: "grid", alignContent: "center"}}>
				<svg 
					viewBox="0 0 20 20" 
					width="30px" 
					style={{transform: "translateY(-20px)"}}
				>
					<polyline
						points="6,7 14,13 6,19"
						stroke="rgba(233, 12, 131, 1)"
						strokeWidth="3"
						fill="none"
					/>
				</svg>
			</div>
			<div style={{display: "grid", gridTemplateRows: "min-content auto", gridGap: "10px"}}>
				<div className="vision-image-wrapper">
					<img src="static/icons/evaluate.svg" alt="pin" height="50px"/>
				</div>
				<div>Evaluate</div>
			</div>
		</div>
	)
}

Icons.displayName="Icons";
// App imports
import './styles.scss';

// Context imports
import { useReverseGeocodingApi } from '../../../context/api/google/reverse';
import { useSurvey } from '../../../context/survey';

export const Title = ({ isInitialState }: any) => {
	const { currentAddress } = useReverseGeocodingApi();
	const { setLocationName } = useSurvey();

	let country = '';
	let city = '';

	if (currentAddress) {
		currentAddress.forEach((item: any) => {
		    if (item.types.includes('country')) {
		        country = item.long_name;
		    }
		    if (
		    	item.types.includes('administrative_area_level_2') ||
		    	item.types.includes('locality') || 
	        	item.types.includes('postal_town') || 
	        	item.types.includes('city')
	        ) {
		        city = item.long_name;
		    }
		});
	}
	const currentLocationName = city === '' ?  "" : [city, country ].join(", ")
	const placeInfo = currentLocationName === '' ? "Select a city you're comfortable mapping." : currentLocationName; 
	setLocationName(currentLocationName)

	return (
		<div className="world-title-wrapper">
			{placeInfo}
		</div>
	)
}

Title.displayName="Title";
// React imports
import { useState } from 'react';

// App imports
import { Arrow } from './arrow';
import { Suggestions } from './suggestions';
import './styles.scss';

// Context imports
import { useSurvey } from '../../../context/survey';

export const Dropdown = () => {
	const { race, setRace } = useSurvey();
	const [ suggestionsActive, setSuggestionsActive ]= useState(false);

	const capitalizeFirstLetter = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const onClick = () => setSuggestionsActive((prev: boolean) => !prev);

	return (
		<div style={{position: "relative"}}>
			<div className="race-dropdown-wrapper" onClick={onClick}>
				<div className="race-maps-input">
					{race ? capitalizeFirstLetter(race) : "Select a race"}
				</div>
				<Arrow/>
				{suggestionsActive && 
					<Suggestions 
						setCurrentRace={setRace}
						setSuggestionsActive={setSuggestionsActive}
					/>
				}
			</div>
		</div>
	)
}

Dropdown.displayName="Dropdown";
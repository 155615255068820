// App imports
import { Statement } from './statement';
import { Contribution } from './contribution';
import { Button } from './button';
import './styles.scss';

export const Vision = () => {
	return (
		<div className="vision-wrapper">
			<Statement/>
			<Contribution/>
			<Button/>
		</div>
	)
}

Vision.displayName="Vision";
// App imports
import { Plus } from './plus';
import './styles.scss';

// Context imports
import { useMapbox } from '../../../../context/maps/mapbox';
import { useMarkers } from '../../../../context/maps/markers';
import { useSlider } from '../../../../context/slider';

export const AddPin = () => {
	const { viewport } = useMapbox();
	const { addPin, markers, setMarkers, setCurrentMarker, setAddPin } = useMarkers();
	const { fillColor } = useSlider();

	const onClick = () => {
		setAddPin((prev: boolean) => !prev);
	};

	return (
		<div 
			className="add-pin" 
			onClick={onClick}
			style={{
				backgroundColor: addPin ? "rgba(233, 12, 131, 1)" : "",
				color: addPin ? "rgba(255, 255, 255, 1)" : ""
			}}
		>
			<Plus addPin={addPin}/>
			<div>{`Add ${markers.length > 0 ? "another" : ""} pin`}</div>
		</div>
	)
}

AddPin.displayName="AddPin";
export const Plus = ({ addPin }: any) => {
	return (
		<svg 
			width="14px" 
			viewBox="0 0 10 10" 
			className="add-pin-plus"
		>
			<line 
				x1={5}
				x2={5}
				y1={0}
				y2={10}
				stroke={addPin ? "rgba(255, 255, 255, 1)" : ""}
			/>
			<line 
				x1={0}
				x2={10}
				y1={5}
				y2={5}
				stroke={addPin ? "rgba(255, 255, 255, 1)" : ""}
			/>
		</svg>
	)
}

Plus.displayName="Plus";
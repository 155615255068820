// App imports
import './styles.scss';

export const Unoun = () => {
	return (
		<div className="ending-bottom">
			<div>Designed & Developed by:</div>
			<img 
				className="logo-unoun" 
				src={process.env.PUBLIC_URL + "/static/logos/unoun_white.svg"} 
				alt="unoun-logo"
			/>
			<div>info@unounstudio.com</div>
			<img 
				src={process.env.PUBLIC_URL + "/static/icons/email.svg"} 
				alt="email" 
				height="20px" 
				style={{margin: "auto"}}
			/>
		</div>
	)
}

Unoun.displayName="Unoun";
// App imports
import './styles.scss';

export const Arrow = ({ markers, topicIndex, setTopicIndex, nextTopic, setMarkers, questions, setCurrentMarker, setQuestions }: any) => {
	const onClick = (index: any) => {
		setQuestions((prevQuestions: any) => {
			const updatedQuestions = [...prevQuestions];
			updatedQuestions[topicIndex].pins = markers; 
			return updatedQuestions;
		});
		setMarkers(questions[index].pins);
		setCurrentMarker(null);
		setTopicIndex(index)
	}

	return (
		<svg 
			viewBox="0 0 20 20" 
			width="40px" 
			className="question-arrow"
			onClick={() => onClick(nextTopic)}
		>
			<circle
				className="question-circle"
				cx={10}	
				cy={10}
				r={9.9}
			/>
			<polyline
				className="question-lines"
				points="
					5.5, 6
					11, 10
					5.5, 14
				"
			/>
			<polyline
				className="question-lines"
				points="
					10, 6
					15, 10
					10, 14
				"
			/>
		</svg>
	)
}

Arrow.displayName="Arrow";
// App imports
import './styles.scss';

import { useSurvey } from 'context/survey';

// Third-party imports
import { useNavigate } from 'react-router-dom';

const genders = [
	"MALE",
	"FEMALE",
	"OTHER",
	"TRANSGENDER",
	"GENDER NEUTRAL",
	"NON-BINARY",
]

export const Gender = () => {
	const navigate = useNavigate();
	const { gender, setGender } = useSurvey(); 

	const onClick = (currentIndex: any) => {
		setGender(genders[currentIndex])
		navigate('/age')
	};

	return (
		<div className="gender">
			<div className="gender-title">
				How do you identify yourself?
			</div>
			<div className="gender-items">
				{genders.map((item: any, index: number) => {
					return (
						<div key={index} onClick={() => onClick(index)}>{item}</div>
					)
				})}
			</div>
		</div>
	)
}

Gender.displayName="Gender";
// App imports
import './styles.scss';

// Context imports
import { useNavigate } from 'react-router-dom';
import { useSurveyApi } from '../../../../../context/api/survey';

export const Done = ({ setTopicIndex }: any) => {
	const navigate = useNavigate();

	const { saveSurvey } = useSurveyApi();

	const onClick = () => {
		saveSurvey();
		navigate('/responses');
		setTopicIndex(0);
	}

	return (
		<div style={{display: "grid", justifyContent: "center"}}>
			<div className="questions-done-button" onClick={onClick}>
				Done
			</div>
		</div>
	)
}

Done.displayName="Done";
// App imports
import './styles.scss';

export const Statement = () => {
	return (
		<div className="statement-wrapper">
			<div className="statement-title">Our cities are designed for men.</div>
			<div className="vision-paragraph">
				Our cities are not neutral, they were built on biases and privileges
				that prioritize certain groups while marginalizing others.
				We are collecting data from across the globe to highlight, champion, and address 
				the challenges faced by diverse communities in urban spaces.
				A Feminist Urban Atlas is a transformative tool to reimagine our cities as inclusive, 
				gender-equal environments that treats everyone equally.
			</div>
		</div>
	)
}

Statement.displayName="Statement";
// App imports
import { Icons } from './icons';
import './styles.scss';

export const Contribution = () => {
	return (
		<div className="contribution-wrapper">
			<div className="vision-contribution">How to contribute?</div>
			<Icons/>
		</div>
	)
}

Contribution.displayName="Contribution";
// Third-party imports
import { useNavigate } from 'react-router-dom';

const races = [ 
	"White", 
	"Asian", 
	"North African", 
	"Black", 
	"African American", 
	"Hispanic or Latino", 
	"Caucasian", 
	"American Indian or Alaska Native", 
	"Native Hawaiian or Other Pacific Islander", 
	"Arabs", 
	"Turks", 
	"Persians", 
	"Phoenicians", 
	"Other"
]

export const Suggestions = ({ setCurrentRace, setSuggestionsActive }: any) => {
	const navigate = useNavigate();

	const handleClick = (index: number) => {
		const currentRace = races[index];
		setCurrentRace(currentRace);
		setSuggestionsActive((false));
		navigate('/gender')
	};
	
	return (
		<ul className="race-suggestions">
			{
				races.map((suggestion: any, index: number) => {
					return (
						<li key={index} onClick={() => handleClick(index)}>
							{suggestion}
						</li>
					)
				})
			}
		</ul>
	)
};

Suggestions.displayName="Suggestions";
// Context imports
import { useTopics } from '../../../../../context/topics';

export const Legend = () => {
	const { questions, topicIndex } = useTopics();
	const currentRank = questions[topicIndex].rank;

	return (
		<div className="slider-legend-wrapper">
			<div>{currentRank[0]}</div>
			<div>{currentRank[1]}</div>
		</div>
	)
}

Legend.displayName="Legend";
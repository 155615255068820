export const Arrow = () => {
	return (
		<div className="race-drop-button-img">
			<svg height="20">
				<polygon points="2.5,2.5 17.5,2.5 10,15" fill="rgba(112, 112, 112, 1)"/>
			</svg>
		</div>
	)
}

Arrow.displayName="Arrow";
// App imports
import { Arrow } from '../arrow';

// Context imports
import { useMarkers } from '../../../../../context/maps/markers';

export const Next = ({ questions, setQuestions, topics, topicIndex, setTopicIndex }: any) => {
	const { markers, setMarkers, setCurrentMarker } = useMarkers();

	return (
		<div>
		<Arrow 
			setTopicIndex={setTopicIndex}
			nextTopic={topicIndex + 1}
			markers={markers} 
			topicIndex={topicIndex} 
			setMarkers={setMarkers} 
			questions={questions} 
			setCurrentMarker={setCurrentMarker} 
			setQuestions={setQuestions}
		/>
		<div className="arrow-text">
			next
		</div>
		</div>
	)
}

Next.displayName="Next";
// App imports
import { Subtitle } from './subtitle';
import { CTA } from './cta';
import './styles.scss';

export const Intro = () => {
	return (
		<div className="intro">
			<div className="intro-title">hello!</div>
			<Subtitle/>
			<CTA/>
		</div>
	)
}

Intro.displayName="Intro";
// Routes
import { Home } from 'components/home';
import { Intro } from 'components/intro';
import { Gender } from 'components/gender';
import { Maps } from 'components/maps';
import { Ages } from 'components/ages';
import { Race } from 'components/race';
import { Ability } from 'components/ability';
import { World } from 'components/world';
import { Vision } from 'components/vision';
import { Responses } from 'components/responses';
import { Results } from 'components/results';
import { Ending } from 'components/ending';

// Third party imports
import { Routes, Route } from 'react-router-dom';

export const AtlasRouter = () => {
	return (
		<Routes>
			<Route path='/' element={<Home/>}/>
			<Route path='/intro' element={<Intro/>}/>
			<Route path='/gender' element={<Gender/>}/>
			<Route path='/age' element={<Ages/>}/>
			<Route path='/maps' element={<Maps/>}/>
			<Route path='/race' element={<Race/>}/>
			<Route path='/world' element={<World/>}/>
			<Route path='/Ability' element={<Ability/>}/>
			<Route path='/vision' element={<Vision/>}/>
			<Route path='/responses' element={<Responses/>}/>
			<Route path='/results' element={<Results/>}/>
			<Route path='/ending' element={<Ending/>}/>
		</Routes>
	)
}

AtlasRouter.displayName="AtlasRouter"